import { combineReducers } from 'redux';
import { netRequest_reducer } from '../util/netReqRD'
import { login_reducer } from './reducers/login';

export const reducer = combineReducers(
    {
        net_request: netRequest_reducer, 
        login: login_reducer,
    }
);




