
const csHost = 'https://angliancd.com';

export const sendRequest = (path, body, headers={}, method='POST') => {
    let request = {
        method: method, 
        // mode: 'cors', 
        headers: Object.assign({
            'Accept': '*/*',
            'Content-Type': 'application/json;charset=UTF-8',
        }, headers), 
        body: JSON.stringify(body),
    };
    
    let url = csHost + path;
    return fetch(url, request)
        .then((response) => {
            // console.log("then response", response)
            const contentType = response.headers.get('content-type');
            if (response.status === 200) {
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return response.json();
                }
                return response.text();
            }else{
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return response.json().then(r => Promise.reject(r));
                }
                return response.text().then(r => Promise.reject(r));
            }
        })
        .then(
            ((json) => {
                // console.error("then then json", json)
                return json;
            }), 
            ((text) => {
                // console.error("then then text", text)
                return text;
            }), 
            ((error) => {
                // console.error("then then error", error)
                return error;
            })
        )
        .catch(
            (error) => {
                // console.error('catch error', error);
                return undefined;
            }
        )
}
