
import { sendRequest } from './net';
import { message } from 'antd';

export const ACT_NET_REQUEST_PENDING = 'ACT_NET_REQUEST_PENDING';
export const ACT_NET_REQUEST_SUCCESS = 'ACT_NET_REQUEST_SUCCESS';
export const ACT_NET_REQUEST_FAILED = 'ACT_NET_REQUEST_FAILED';
export const ACT_NET_REQUEST_RESET = 'ACT_NET_REQUEST_RESET';
export const ACT_NET_REQUEST_SET_MSG = 'ACT_NET_REQUEST_SET_MSG';
export const ACT_NET_REQUEST_CLEAR = 'ACT_NET_REQUEST_CLEAR';

const netRequestState = {};

export const netRequest_reducer = (state=netRequestState, action) => {
    let nextState = Object.assign({}, state);
    let item = nextState[action.routeCode]
    switch(action.type){
        case ACT_NET_REQUEST_PENDING: {
            if (item === undefined){
                nextState[action.routeCode] = {status: 1, routeCode: action.routeCode, msg: undefined};
            } else {
                item.status = 1;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_SUCCESS: {
            if (item === undefined){
                nextState[action.routeCode] = {status: 2, routeCode: action.routeCode, msg: action.msg};
            } else {
                item.status = 2;
                item.msg = action.msg;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_FAILED: {
            if (item === undefined){
                nextState[action.routeCode] = {status: 0, routeCode: action.routeCode, msg: undefined};
            } else {
                item.status = 0;
                item.msg = undefined;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_RESET: {
            if (item !== undefined){
                item.status = 0;
                item.msg = undefined;
            }
            
            return nextState;
        }

        case ACT_NET_REQUEST_SET_MSG: {
            if (item === undefined){
                nextState[action.routeCode] = {status: 2, routeCode: action.routeCode, msg: action.msg};
            } else {
                item.msg = action.msg;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_CLEAR: {
            if (item !== undefined){
                delete nextState[action.routeCode];
            }
            return nextState;
        }

        default:
            return state;
    }
}



// select handle
export const getNetRequestStatus = (state, routeCode) => {
    let element = state.net_request.find((item) => item.routeCode === routeCode)
    return element ? element.status : 0;
}

export const getNetRequestMsg = (state, routeCode) => {
    let element = state.net_request.find((item) => item.routeCode === routeCode)
    return element ? element.msg : undefined;
}

// actions define
// ACT_NET_REQUEST_PENDING
export const actNetRequestPending = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_PENDING, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_SUCCESS
export const actNetRequestSuccess = (routeCode, msg=undefined) => {
    return {
        type: ACT_NET_REQUEST_SUCCESS, 
        routeCode: routeCode, 
        msg: msg
    }
};

// ACT_NET_REQUEST_FAILED
export const actNetRequestFailed = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_FAILED, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_RESET
export const actNetRequestReset = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_RESET, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_CLEAR
export const actNetRequestClear = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_CLEAR, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_SET_MSG
export const actNetRequestSetMsg = (routeCode, msg) => {
    return {
        type: ACT_NET_REQUEST_SET_MSG, 
        routeCode: routeCode, 
        msg: msg
    }
};

export const netRequestThunk = (path, msgBody, header, routeCode, successHd=undefined, errorHd=undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(routeCode));
    // var temple=await sleep(3000);
    const body = await sendRequest(path, msgBody, header);
    if (body !== undefined){
        // console.log(body, Object.prototype.toString.call(body));
        if (body.status !== 0){
            dispatch(actNetRequestFailed(routeCode));

            if (errorHd !== undefined){
                if (errorHd(dispatch, body.status, body.statusMsg, msgBody)){
                    return;
                }
            }

            if (body.statusMsg !== undefined && body.status !== undefined){
                message.error({content: `操作失败! 错误码: ${body.status} 错误提示: ${body.statusMsg}`, key: "message", duration: 5});
            /* body是一个TypeError类型 */
            }else if (body.message === "Failed to fetch"){
                message.error({content: `操作失败! 错误提示: 服务端无响应`, key: "message", duration: 5});
            }
            else{
                message.error({content: `操作失败! 错误提示: 服务端错误`, key: "message", duration: 5});
            }
        }else{
            if (successHd !== undefined){
                successHd(dispatch, body, msgBody);
            }
            dispatch(actNetRequestSuccess(routeCode));
        }
    }else{
        dispatch(actNetRequestFailed(routeCode));
        if (errorHd !== undefined){
            if (errorHd(dispatch, -1, "服务器无响应", msgBody)){
                return;
            }
        }
        message.error({msg:`操作失败! 服务器无响应`, key: "message", duration: 5});
    }
}


export const netRequestThunk2 = (path, msgBody, header, routeCode, successHd=undefined, errorHd=undefined, noauthHd=undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(routeCode));
    // var temple=await sleep(3000);
    const body = await sendRequest(path, msgBody, header);
    if (body !== undefined){
        // console.log(body);
        if (body.Status !== 0){
            dispatch(actNetRequestFailed(routeCode));
            
            // token失效
            if (body.Status === 100008){
                if (noauthHd){
                    noauthHd(body);
                    return;
                }
            }   

            if (errorHd !== undefined){
                if (errorHd(dispatch, body, msgBody)){
                    return;
                }
            }

            if (body.StatusMsg !== undefined && body.Status !== undefined){
                message.error({content: `操作失败! 错误码: ${body.Status} 错误提示: ${body.StatusMsg}`, key: "message", duration: 5});
            /* body是一个TypeError类型 */
            }else if (body.message === "Failed to fetch"){
                message.error({content: `操作失败! 错误提示: 服务端无响应`, key: "message", duration: 5});
            }
            else{
                message.error({content: `操作失败! 错误提示: 服务端错误`, key: "message", duration: 5});
            }
        }else{
            if (successHd !== undefined){
                successHd(dispatch, body, msgBody);
            }
            dispatch(actNetRequestSuccess(routeCode));
        }
    }else{
        dispatch(actNetRequestFailed(routeCode));
        if (errorHd !== undefined){
            if (errorHd(dispatch, -1, "服务器无响应", msgBody)){
                return;
            }
        }
        message.error({msg:`操作失败! 服务器无响应`, key: "message", duration: 5});
    }
}