import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import {Switch} from 'react-router';
import {Provider} from 'react-redux';
import { ConfigProvider } from 'antd';
import {configStore, loadStoreState} from './redux/store';

import Main from './pages/main/main';

import './index.css';

import zh_CN from 'antd/lib/locale-provider/zh_CN';
import * as serviceWorker from './serviceWorker';

const store = configStore(loadStoreState());

ReactDOM.render(
    <ConfigProvider locale={zh_CN}>
        <Provider store={store}>
            <BrowserRouter basename="/vlive">
                <Switch>
                    <Route exact path='/' component={Main}/>
                </Switch>
            </BrowserRouter>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
