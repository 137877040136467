

const ACT_LOGIN = 'ACT_LOGIN';
const ACT_USER_INFO = 'ACT_USER_INFO';

// reducer handle
const loginState = {
    login: undefined,
    user: undefined,
};

export const login_reducer = (state=loginState, action) => {
    switch(action.type) {
        case ACT_LOGIN:
            state.login = action.login;
            break;
        case ACT_USER_INFO:
            state.user = action.user;
            break;

        default:
            break;
    }
    
    return state;
}

export const getLoginData = (state) => {
    return state.login.login;
}

export const saveLoginData = (login) => {
    return {
        type: ACT_LOGIN,
        login: login,
    };
} 

export const getUserData = (state) => {
    return state.login.user;
}

export const saveUserData = (user) => {
    return {
        type: ACT_USER_INFO,
        user: user,
    }
} 

export const loginNoauthHd = (histroy, rspBody) => {
    switch(rspBody.Status){
        case 50002:
        case 50003:
            return 'Token已失效，请重新登录';

        default:
            return rspBody.StatusMsg;
    }
}