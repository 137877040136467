import { netRequestThunk2 } from '../util/netReqRD';

export const ACT_NET_BOARD_INFO = 'conferenceBoardStart';

export const apiLiveInfo = (props, code, successFun = undefined, errorFun = undefined) => {
    const { dispatch } = props;
    const path = '/cs/v1/app/conference/live/info';
    
    let body = {
        ConferenceCode: code,
        CheckCode: "66e432c2-e3df-44de-8646-40910e5ef30e"
    }
    dispatch(netRequestThunk2(path, body, undefined, ACT_NET_BOARD_INFO, successFun, errorFun));
}
