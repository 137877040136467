import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Result, Space, Button, Row, Col, message, Drawer, Tooltip, Menu, Dropdown, Descriptions, } from 'antd';
import { apiLiveInfo } from '../../api/conference';
import {IconFont} from '../../util/iconFont';
import Timer from '../../component/timer/timer';
import UUID from '../../util/uuid';

import './main.css';
import './drawer.css';
import { CloseOutlined } from '@ant-design/icons';

class Main extends Component {

    MENU_UNLOCK = 0
    MENU_LOCK = 1

    BACKGROUD_VIEW_BOARD = 1
    BACKGROUD_VIEW_LIVE = 2

    constructor(props){
        super(props);

        this.mqttOnSubscribe = this.mqttOnSubscribe.bind(this);
        this.mqttOnMessage = this.mqttOnMessage.bind(this);

        this.flvErrorHd = this.flvErrorHd.bind(this);
        this.flvSourceCloseHd = this.flvSourceCloseHd.bind(this);
        this.flvMediaInfoHd = this.flvMediaInfoHd.bind(this);
        this.flvVideoFrozenHd = this.flvVideoFrozenHd.bind(this);

        this.barVisibleOnToggle = this.barVisibleOnToggle.bind(this);
        this.mainDivOnMouseMove = this.mainDivOnMouseMove.bind(this);
        this.menuItemOnClick = this.menuItemOnClick.bind(this);
        this.iconToggleOnClick = this.iconToggleOnClick.bind(this);
        this.shareOnClick = this.shareOnClick.bind(this);
        
        this.state = {
            debug: false,

            status: true,

            barVisible: false,

            liveEnable: true,
            boardEnable: false,

            menuLock: this.MENU_UNLOCK,
            backgroudView: this.BACKGROUD_VIEW_LIVE,
        };
    }

    // 获取url中?后的参数数据
    getUrlParam = (name, str) => {
        let reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
        let r = str.substr(1).match(reg);
        return r != null ? decodeURIComponent(r[2]) : null; 
    }

    // 出错
    flvErrorHd(errType, detail) {
        switch(errType) {
            case 'NetworkError':
                break;
            case 'MediaError':
                break;
            case 'OtherError':
                break;
            default:
                break;
        }
    }

    // 流关闭了
    flvSourceCloseHd(e) {
        console.log(e)
    }

    // 分辨率发生变化
    flvResoulutionChangeHd(e) {
        console.log(e)
    }

    // 音视频媒体信息
    flvMediaInfoHd(e) {
        console.log(e)
    }

    // 视频卡住
    flvVideoFrozenHd(e) {
        console.log(e)
    }

    mqttOnSubscribe(err, topic) {
        if (!err) {
            console.info(`mqtt subscribe success. topic: ${topic}`);
        } else {
            console.error(`mqtt subscribe failed. topic: ${topic}`);
        }
    }

    mqttOnMessage(topic, msg) {
        let that = this;
        let { conferenceInfo, mqttTopicConference } = this.state;
        // message is Buffer
        let content = undefined;
        try {
            content = JSON.parse(msg.toString()) || {};
        } catch (error) {
            content = {};
        }
        console.log("mqtt message: ", content)
        if (topic === mqttTopicConference) {
            switch(content.MsgCode) {
                case 1000: {// 会议创建
                    break;
                }
                case 1001: {// 会议释放
                    break; 
                }
                case 1002: {// 成员入会
                    break;
                }
                case 1003: {// 成员离会
                    break;
                }
                case 1100: {// 白板开始共享
                    message.info({content: "白板开始共享", key: "message", duration: 3});
                    conferenceInfo.BoardInfo.BoardUrlHttps = content.BoardInfo.BoardUrlHttps;
                    that.setState({
                        conferenceInfo: conferenceInfo,
                        boardEnable: true,
                        liveEnable: true,
                        backgroudView: this.BACKGROUD_VIEW_BOARD,
                    })
                    break;
                }
                case 1101: {// 白板结束共享
                    message.info({content: "白板停止共享", key: "message", duration: 3});
                    conferenceInfo.BoardInfo.BoardUrlHttps = undefined;
                    that.setState({
                        conferenceInfo: conferenceInfo,
                        liveEnable: true,
                        boardEnable: false,
                        backgroudView: this.BACKGROUD_VIEW_LIVE,
                    })
                    break;
                }
                default:
                    console.error("unsupported msg code: ", content.MsgCode)
                    break;
            }   
        } 
    }

    mqttStart(conferenceInfo) {
        let that = this;
        let mqtt = require('mqtt')
        let mqttClient = undefined;
        let config = {
            host: conferenceInfo.MqttInfo.ServerWssHost,
            port: conferenceInfo.MqttInfo.ServerWssPort,
            username: conferenceInfo.MqttInfo.Account,
            password: conferenceInfo.MqttInfo.Password,
            // protocolVersion: 4,
            clientId: `live_client_${new UUID().toString()}}`,
            
            keepalive: 10,
            protocol: 'wss',
        }

        try {
            mqttClient = mqtt.connect('', config)
        } catch (error) {
            console.error("catch: ", error)
        }

        if (!mqttClient) {
            console.error("mqtt init failed.")
            return mqttClient;
        }

        console.info("mqtt conntecting. config", config)

        let topicConference = `cs/app/notify/conference/${conferenceInfo.ConfInfo.ConferenceId}`;

        mqttClient.on('connect', () => {
            console.log("mqtt connected")
            mqttClient.subscribe(topicConference, (err) => that.mqttOnSubscribe(err, topicConference))
        })
        mqttClient.on('message', this.mqttOnMessage)

        this.setState({
            mqttClient: mqttClient,
            mqttTopicConference: topicConference,
        })
        return mqttClient;
    }

    liveStart(conferenceInfo) {
        let videoElement = document.getElementById('video_live');
        let url = `${conferenceInfo.LiveInfo.Url}/live?port=${conferenceInfo.LiveInfo.Port}&app=${conferenceInfo.LiveInfo.App}&stream=${conferenceInfo.ConfInfo.SipCode}`;
        let flvjs = window.flvjs;
        console.info("live start. url:", url)
        if (flvjs && flvjs.isSupported()) {
            let player = flvjs.createPlayer({
                type: 'flv',
                isLive: true, 
                url: url,
            });
            player.attachMediaElement(videoElement);
            player.load();
            player.play();
            player.on(flvjs.Events.ERROR, this.flvErrorHd);
            player.on(flvjs.Events.MEDIA_SOURCE_CLOSE, this.flvSourceCloseHd);
            player.on(flvjs.Events.MEDIA_SOURCE_ENDED, this.flvSourceCloseHd);
            player.on(flvjs.Events.MEDIA_INFO, this.flvMediaInfoHd);
            player.on(flvjs.Events.VIDEO_FROZEN, this.flvVideoFrozenHd);

        } else {
            message.error({content: "不支持flv", key: "message", duration: 5});
        }
    }

    barVisibleOnToggle(e) {
        let that = this;
        let { barVisible , menuLock } = this.state;

        if (menuLock !== this.MENU_LOCK) {
            if (this.barClickTimeout) {
                clearTimeout(this.barClickTimeout);
                this.barClickTimeout = null;
            }
    
            this.barClickTimeout = setTimeout(() => {
                that.setState({
                    barVisible: !barVisible,
                })
                that.barClickTimeout = null;
            }, 500)
        }
    }

    shareOnClick(e) {
        let {conferenceInfo} = this.state;
        let input = document.getElementById('conference_url');
        input.setAttribute('value', 
            window.location.protocol + "//" + window.location.host + "?conference=" + (conferenceInfo ? conferenceInfo.ConfInfo.ConferenceCode : ""));
        // input.setSelectionRange(0, 9999);
        input.select()
        if (document.execCommand('copy')) {
            document.execCommand('copy')
            message.success({content: "会议地址已复制到剪贴板", key: "tip", duration: 2});
        } else {
            console.error("复制会议地址失败");
        }
    }

    menuItemOnClick(e, param) {
        // console.log(key)
        let state = this.state;
        let { conferenceInfo } = state;
        let key = parseInt(e.key);
        switch(param) {
            case 'backgroudView':{
                if (key === this.BACKGROUD_VIEW_BOARD) {
                    if (conferenceInfo.BoardInfo.BoardUrlHttps) {
                        state.liveEnable = true;
                    } else {
                        message.warn({content: "白板未共享", key: "message", duration: 5});
                        return;
                    }
                } else if (key === this.BACKGROUD_VIEW_REMOTE) {
                    if (conferenceInfo.BoardInfo.BoardUrlHttps) {
                        state.boardEnable = true;
                    }
                } else {
                    console.error("unsupported key: ", key, "with param: ", param);
                }
                break;
            }
            default:
                break;
        }

        state[param] = key;
        this.setState({
            ...state,
        })
    }

    // 鼠标移到底层框附近出现菜单
    mainDivOnMouseMove(e) {
        let {height, width} = this.state;

        // console.log("mouse move", width, height, e.screenX, e.screenY)
        if( e.clientY < 8 || height - e.clientY < 8 
            || e.clientX < 8 || width - e.clientX < 8){
            this.setState({
                barVisible: true,
                mouseCurX: e.clientX,
                mouseCurY: e.clientY,
            })
        } else {
            this.setState({
                mouseCurX: e.clientX,
                mouseCurY: e.clientY,
            })
        }
    }

    iconToggleOnClick(e, type) {
        let { conferenceInfo } = this.state;
        switch(type){
            case 'live':{
                this.setState({
                    liveEnable: !this.state.liveEnable,
                })
                break;
            }
            case 'board':{
                if (conferenceInfo && conferenceInfo.BoardInfo.BoardUrlHttps) {
                    this.setState({
                        boardEnable: !this.state.boardEnable,
                    })
                } else {
                    message.warn({content: "白板还未共享", key: "message", duration: 3});
                }
                break;
            }
                
            default:
                break;
        }
    }

    mkLive() {
        let { backgroudView, liveEnable, height, width } = this.state;
        let style = {};
        let toggleBackgroudView = this.BACKGROUD_VIEW_BOARD;

        switch(backgroudView) {
            case this.BACKGROUD_VIEW_BOARD:
                toggleBackgroudView = this.BACKGROUD_VIEW_LIVE;
                style.visibility = !liveEnable ? 'hidden' : 'visible';
                break;
            case this.BACKGROUD_VIEW_LIVE:
                toggleBackgroudView = this.BACKGROUD_VIEW_BOARD;
                style.height = height;
                style.width = width;
                break;
            default:
                break;
        }

        return (
            <div className="live" style={style}>
                <div className="icons">
                    <Tooltip title="切换窗口">
                        <IconFont type="icon-switch" className="icon right" onClick={(e) => {this.setState({backgroudView: toggleBackgroudView})}}/>
                    </Tooltip>
                    <Tooltip title="关闭窗口">
                        <IconFont type="icon-close" className="icon right" onClick={(e) => {this.setState({liveEnable: false})}}/>
                    </Tooltip>
                </div>
                <video className="video" width="100%" height="100%" id="video_live" autoPlay muted />
            </div>
        )
    }

    mkBoard() {
        let { backgroudView, conferenceInfo, boardEnable, height, width } = this.state;
        let style = {};
        let src = (conferenceInfo && conferenceInfo.BoardInfo.BoardUrlHttps) || "";

        switch(backgroudView) {
            case this.BACKGROUD_VIEW_BOARD:
                style.height = height;
                style.width = width;
                break;
            case this.BACKGROUD_VIEW_LIVE:
                style.visibility = !boardEnable ? 'hidden' : 'visible';
                break;
            default:
                break;
        }
        return (
            <div className="board" style={style} >
                <iframe 
                    title="board"
                    style={{width:'100%', height: '100%', overflow:'visible'}}
                    src={src}
                    scrolling="no" 
                    frameBorder="0"
                    />
            </div>
        )
    }

    mkHeader() {
        let { barVisible, conferenceInfo, } = this.state;
        let rightIcons = [];
        let startTime = conferenceInfo ? new Date(Date.parse(conferenceInfo.ConfInfo.StartTime.replace(/-/g, "/"))).getTime() / 1000 : 0;

        rightIcons.push(
            <Tooltip key="copy" overlayClassName={"tooltip-normal"} title="点我复制会议地址" placement="bottomRight">
                <IconFont className="icon right" type="icon-share2" onClick={this.shareOnClick}/>
            </Tooltip>
        )
        
        return <Drawer
            className="drawer-header"
            placement={"top"}
            closable={false}
            visible={barVisible}
            mask={false}
            keyboard={false}
            height={64}
            zIndex={400}
            key={"drawer_top"}
            >
            <Row className="content" justify="space-around" align="middle">
                <Col span={8} className="icons">
                </Col>
                <Col span={8} className="icons">
                    <span className="theme" >
                        {conferenceInfo ? conferenceInfo.ConfInfo.Title : undefined}
                    </span>
                    <Timer className="timer" 
                        start={startTime}
                        onDoubleClick={(e) => this.setState({debug: !this.state.debug})}
                        />
                </Col >
                <Col span={8} className="icons">
                    {rightIcons}
                </Col>
            </Row>
        </Drawer>
    }

    mkFooter() {
        let { barVisible, conferenceInfo, backgroudView, menuLock,
            boardEnable, liveEnable, 
        } = this.state;
        let leftIcons = [];
        let midIcons = [];
        let rightIcons = [];

        if (backgroudView === this.BACKGROUD_VIEW_BOARD) {
            leftIcons.push(
                <Tooltip key="live" overlayClassName="tooltip-normal" title={liveEnable ? "直播预览：打开" : "直播预览：关闭"} placement="topRight">
                    <IconFont className={liveEnable ? "icon left selected" : "icon left"} type="icon-room" onClick={(e) => this.iconToggleOnClick(e, 'live')}/>
                </Tooltip>
            )
        } else if (backgroudView === this.BACKGROUD_VIEW_LIVE) {
            if (conferenceInfo && conferenceInfo.BoardInfo.BoardUrlHttps) {
                leftIcons.push(
                    <Tooltip key="board" overlayClassName="tooltip-normal" title={boardEnable ? "白板预览：打开" : "白板预览：关闭"} placement="topRight">
                        <IconFont className={boardEnable ? "icon left selected" : "icon left"} type="icon-whiteboard" onClick={(e) => this.iconToggleOnClick(e, 'board')}/>
                    </Tooltip>
                )
            }
        }

        rightIcons.push(
            <Dropdown key="backgroudView"
                overlay={<Menu onClick={(e) => this.menuItemOnClick(e, 'backgroudView')}>
                    <Menu.Item key={this.BACKGROUD_VIEW_BOARD}><span className="menu-item"><IconFont type={backgroudView === this.BACKGROUD_VIEW_BOARD ? "icon-right" : ""} /><span className="description">图层切换: 白板</span></span></Menu.Item>
                    <Menu.Item key={this.BACKGROUD_VIEW_LIVE}><span className="menu-item"><IconFont type={backgroudView === this.BACKGROUD_VIEW_LIVE ? "icon-right" : ""} /><span className="description">图层切换: 直播画面</span></span></Menu.Item>
                </Menu>}
            >
                <IconFont className="icon right" type="icon-background"/>
            </Dropdown>
        )

        if (menuLock !== this.MENU_LOCK) {
            rightIcons.push(
                <Tooltip key="barVisible" overlayClassName={"tooltip-normal"} title="隐藏菜单栏" placement="bottomRight">
                    <IconFont className="icon right" type="icon-hidden" onClick={this.barVisibleOnToggle}/>
                </Tooltip>
            )
        }
        
        return <Drawer
            className="drawer-footer"
            placement={"bottom"}
            closable={false}
            visible={barVisible}
            mask={false}
            keyboard={false}
            height={64}
            zIndex={500}
            key={"drawer_bottom"}
            >
            <Row className="content" justify="space-around" align="middle">
                <Col span={8} className="icons">
                    {leftIcons}
                </Col>
                <Col span={8} className="icons">
                    {midIcons}
                </Col>
                <Col span={8} className="icons">
                    {rightIcons}
                </Col>
            </Row>
        </Drawer>
    }

    mkDebug() {
        let { debug } = this.state;
        let component = undefined;
        if (debug) {
            component = <Descriptions className="debug" title={
                    <Row justify="space-between" align="middle">
                        <Col><span className="title">debug信息</span></Col>
                        <Col><CloseOutlined className="icon" onClick={(e) => this.setState({debug: false})}/></Col>
                    </Row>
                } bordered size="small" column={1}>
                <Descriptions.Item label="分辨率">{this.state.width} × {this.state.height}</Descriptions.Item>
                <Descriptions.Item label="鼠标位置">
                    <span>X:{this.state.mouseCurX}</span>
                    <br/>
                    <span>Y:{this.state.mouseCurY}</span>
                </Descriptions.Item>
            </Descriptions>
        }

        return component;
    }

    mkMain() {
        let { backgroudView } = this.state;
        return <div id='main' className="main">
            {this.mkDebug()}
            {this.mkHeader()}
            <div className={backgroudView === this.BACKGROUD_VIEW_BOARD ? "board-overlap" : "live-overlap"}
                onMouseMove={this.mainDivOnMouseMove} 
                onClick={this.barVisibleOnToggle}
            >
                {this.mkLive()}
                {this.mkBoard()}
            </div>
            {this.mkFooter()}
            <div>
                <input id='conference_url' v-model='conference_url' style={{opacity: 0, position: 'absolute'}} type="text"/>
            </div>
        </div>
    }

    componentWillUnmount() {
        
    }

    componentDidMount() {
        let that = this;
        this.setState({
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
        });
        window.addEventListener('resize', () => {
            this.setState({
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth,
            })
        });
        let code = this.getUrlParam("conference", this.props.location.search);
        setTimeout(() => {
            apiLiveInfo(this.props, code, (dispatch, rsp, req) => {
                that.mqttStart(rsp);
                that.liveStart(rsp);
                that.setState({
                    status: true,
                    conferenceInfo: rsp,
                    boardEnable: !!rsp.BoardUrlHttps,
                })
                window.document.getElementById('main').style.backgroundColor = '#171717';
                window.document.getElementById('main').style.backgroundImage = '#171717';
            }, (dispatch, rsp, req) => {
                console.error("get board info failed. rsp:", rsp)
                let reason = rsp.StatusMsg;
                switch(rsp.Status) {
                    case 51000:
                        reason = "该会议不存在";
                        break;
                    case 52001:
                        reason = "该会议没有直播";
                        break;
                    default:
                        break;
                }
                that.setState({
                    status: false,
                    reason: reason,
                })
                return true;
            })
        }, 1000)
        
    }

    render() {
        let {status, reason, height, width} = this.state;
        if (status) {
            return this.mkMain();
        } 

        return <div className="not-find-wapper" style={{height: height, width: width}}>
            <Result
                className="not-find"
                status="404"
                title="404"
                subTitle={reason}
                extra={<Space>
                    <Button type="primary" onClick={(e) => {
                        window.location.reload()
                    }}>刷新</Button>
                    <Button type="primary" danger onClick={(e) => {
                        window.open('','_parent','');  
                        window.close();  
                    }}>离开</Button>
                </Space>}
            />
        </div>;
    }
}

let mapState = (state) => ({
});

export default connect(
    mapState, 
    null
)(Main);


